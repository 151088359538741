import "./styles/app.css";
import Page from "./components/Page";

function App() {
  return (
    <>
      <Page />
    </>
  );
}

export default App;
